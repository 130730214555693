// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.crud-table {
  color: #1976d2;
  cursor: pointer;
}

.crud-button {
  margin-right: 1em;
  margin-top: 1.5em;
}

.cell-tight {
  width: 50px;
  padding-left: 6;
  padding-right: 6;
}

.content-pointer-event-none {
  pointer-events: none;
}

// rows per page height change
.MuiInputBase-colorPrimary {
  margin-bottom: 15px;
}

.MuiTablePagination-actions {
  margin-bottom: 15px;
}
